import { AfterContentInit, AfterViewInit, Component, ContentChildren, Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Query, QueryList, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, animateChild, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'dynamic-list-item',
  template: `
    <div class="dynamic-list-item" #ref>
      <ng-content></ng-content>
    </div>
  `,
  styles: [`
    :host{
      display: block;
    }
    .dynamic-list-item {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
      padding: .5rem;
      overflow: hidden;
    }

    :host:first-of-type .dynamic-list-item {
      border-radius: .4rem .4rem 0 0;
    }

    :host:last-child .dynamic-list-item {
      border-bottom: 1px solid #ddd;
      border-radius: 0 0 .4rem .4rem;
    }

    :host:only-of-type .dynamic-list-item {
      border-radius: .4rem;
    }
  `],
  animations: [
    trigger('InsertRemovetrigger', [
      transition(':enter', [
        style({
          opacity: 0,
          height: 0,
          transform: 'translateY(-18px)'
        }),
        animate('200ms ease-out', style({
          opacity: 1,
          height: '*',
          transform: 'translateY(0)'
        })),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
          height: '*',
          transform: 'translateY(0)'

        }),
        animate('200ms ease-out', style({
          opacity: 0,
          height: 0,
          transform: 'translateY(-18px)'
        })),
      ])
    ])
  ]
})
export class DynamicListItemComponent implements OnInit {

  // binds the animation to the host component
  @HostBinding('@InsertRemovetrigger') get getInsertRemovetrigger() { return true };

  constructor() { }

  ngOnInit(): void {
  }
}

@Component({
  selector: 'dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.css']
})
export class DynamicListComponent {

  @ContentChildren(DynamicListItemComponent) items!: QueryList<DynamicListItemComponent>;
  @Input() borderless = false;

  constructor() { }
}

