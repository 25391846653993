
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  chatApiUrl: 'https://api.go.gov.br/controladoria/sispac/chatbot/v1.0',
  chatTokenUrl: 'https://api.go.gov.br/token',
  chatConsumerKey: 'YBXLv5cJTs_cmN52BF6eUXeGmRwa',
  chatConsumerSecret: 'Bv_3lBxyYc4NH8xKMJy0SMV553oa',
  apiUrl: 'https://wwwhomolog.correicao.go.gov.br/api/api/',
  apiUrlFiles: 'https://wwwhomolog.correicao.go.gov.br/files/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
