<div
  *ngIf="titulo || icone"
  class="d-flex align-items-center bg-secondary text-light rounded-top p-2"
>
  <mat-icon>{{ icone }}</mat-icon>
  <h5 class="ml-3 mb-0" [ngClass]="{ 'opacity-50': disabled }">
    {{ titulo }}
  </h5>
</div>
<app-input-loading
  [isLoading]="loading"
  [relativePosition]="true"
  [legacyInput]="true"
></app-input-loading>
<quill-editor
  #editor
  class="w-100"
  [ngClass]="{ 'opacity-50': disabled }"
  name="pecaEditor"
  rows="16"
  [placeholder]="placeholder"
  (onContentChanged)="onContentChange($event)"
>
</quill-editor>

<mat-error *ngIf="control.hasError('required') && control.touched" class="fs-10"
  >Campo Obrigatório
</mat-error>
