import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-resizable-container',
  templateUrl: './resizable-container.component.html',
  styleUrls: ['./resizable-container.component.css']
})
export class ResizableContainerComponent {

  @ViewChild('resizable') container: ElementRef;
  @Input() minWidth = 100;
  @Input() maxWidth = 700;
  width: number = 400;
  oldWidth:number = 0
  grabber = false;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabber) {
        return;
    }
    this.resizer(event.clientX - this.oldWidth);
    this.oldWidth = event.clientX;
  }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.grabber = false;
  }

  onMouseDown(event: MouseEvent) {
    this.grabber = true;
    this.oldWidth = event.clientX;
    event.preventDefault();
  }
  resizer(offsetY: number) {
    this.width += offsetY;
    if(this.width >= this.maxWidth) this.width = this.maxWidth;
    if(this.width <= this.minWidth) this.width = this.minWidth;
  }
}
