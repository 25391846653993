import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulosComponent } from './main/modulos/modulos.component';
import { MasterComponent } from './master.component';
import { AuthenticationGuard } from './authentication/authentication.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'chatbot', loadChildren: () => import('./modules/chatbot/chatbot.module').then(m => m.ChatbotModule)},
  { path: 'modulos', component: ModulosComponent, loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path: 'main', component: MasterComponent, loadChildren: () => import('./master.module').then(m => m.MasterModule), canActivate: [AuthenticationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
