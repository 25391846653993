import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.css']
})
export class DialogFormComponent implements OnInit {
  @Inject(MAT_DIALOG_DATA) static _data: any;
  static _dialogRef: any;

  constructor() { }

  ngOnInit(): void {

  }

  set data(data) {
    DialogFormComponent._data = data;
  }

  get data() {
    return DialogFormComponent._data;
  }

  set dialogRef(dialogRef) {
    DialogFormComponent._dialogRef = dialogRef;

  }

  get dialogRef() {
    return DialogFormComponent._dialogRef;
  }

  closeDialogRef(valueCB: any = true) {
    this.dialogRef.close(valueCB);
  }

  closeDialogRefData(data:any){
    this.dialogRef.close(data);
  }
}
