<div class="content" #content>
</div>
<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="null" class="ml-3">Voltar</button>
    <button mat-raised-button #t="matMenuTrigger" [matMenuTriggerFor]="menu" color="primary">
        Exportar <mat-icon id="acoesIcon" [ngClass]="{'active': t.menuOpen}">expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item (click)="exportWord()" [mat-dialog-close]="null" color="primary">Word</button> -->
        <button mat-menu-item (click)="exportPDF()" [mat-dialog-close]="null" color="primary">PDF</button>
    </mat-menu>
</mat-dialog-actions>
