import { Injectable } from "@angular/core";
import { HttpService } from "src/app/utils/http-service.service";

@Injectable({
  providedIn: 'root'
})

export class ServidorService {
  constructor(private service: HttpService) {
  }

  novoServidor(params){
    return this.service.sendPost(`admin/servidores`, params);
  }
  updateServidor(id,params){
    return this.service.sendPatch(`admin/servidores/${id}`, params);
  }
  buscaServidor(id){
    return this.service.sendGet(`admin/servidores/${id}`);
  }
}