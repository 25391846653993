<div class="d-flex gap-3 p-3">
  <div class="option d-flex flex-column align-items-center gap-3 border rounded p-3" [mat-dialog-close]="'nao_identificado'">
    <mat-icon class="big-icon pb-5">indeterminate_question_box</mat-icon>
    <div class="description">
      <h5>Agente não identificado</h5>
    </div>
  </div>
  <div class="option d-flex flex-column align-items-center gap-3 border rounded p-3" [mat-dialog-close]="'identificado'">
    <mat-icon class="big-icon pb-5">frame_person</mat-icon>
    <div class="description">
      <h5>Agente identificado</h5>
    </div>
  </div>
</div>
<button class="mb-3 ml-3" mat-raised-button color="primary" [mat-dialog-close]="null">Voltar</button>
