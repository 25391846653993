import { Component, Inject, OnInit } from '@angular/core';
import { ResetPasswordService } from './reset-password.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private resetPasswordService: ResetPasswordService,
    private fb: UntypedFormBuilder,
    private snackBar: SnackBarService
  ) {
    this.resetPasswordForm = this.fb.group({
      senhaNova: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.compose([specialCharsValidator, numberValidator, upperCaseValidator, lowerCaseValidator])]],
      confirmarSenha: [''],
    });
  }

  ngOnInit(): void {
    const validatorBound = this.confirmPasswordValidator.bind(this);
    this.resetPasswordForm.get('confirmarSenha')
      .setValidators([validatorBound]);
    this.resetPasswordForm.get('confirmarSenha')
      .updateValueAndValidity();
    this.resetPasswordForm.get('senhaNova').valueChanges
      .subscribe(() => {
        this.resetPasswordForm.get('confirmarSenha')
          .updateValueAndValidity();
      });
  }
  editarSenha() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    const data = this.resetPasswordForm.getRawValue();
    this.resetPasswordService.resetPassword(data)
      .subscribe(
        (ret) => {
          this.snackBar.showSnackBarSuccess('Senha alterada com sucesso');
          this.dialogRef.close();
        },
        error => {
          this.snackBar.showSnackBarError('Erro ao alterar senha');
          this.dialogRef.close();
        });
  }
  confirmPasswordValidator(form: AbstractControl): ValidationErrors | null {
    const password = this.resetPasswordForm.get('senhaNova');
    const confirmPassword = form;
    if (password.value !== confirmPassword.value) {
      return { passwordsNotMatching: true };
    }
    return null;
  }

  getErrorText() {
    const errors = Object.keys(this.resetPasswordForm.get('senhaNova').errors);
    let msg = 'A senha deve conter';
    errors.forEach((error, index) => {
      if (error === 'minlength') {
        msg += ' no mínimo 6 caracteres';
      } else if (error === 'upperCase') {
        msg += ' uma letra maiúscula';
      } else if (error === 'lowerCase') {
        msg += ' uma minúscula';
      } else if (error === 'number') {
        msg += ' um número';
      } else if (error === 'specialChars') {
        msg += ' um caractere especial';
      }
      if (index === errors.length - 1) {
        msg += '.';
      } else if(index === errors.length - 2) {
        msg += ' e ';
      } else {
        msg += ', ';
      }
    });
    return msg;
  }
}

const specialCharsValidator = (control: AbstractControl): ValidationErrors | null => {
  const regex = /[!@#$%^&*(),.?":{}|<>]/g;
  const valid = regex.test(control.value);
  return valid ? null : { specialChars: true };
};

const numberValidator = (control: AbstractControl): ValidationErrors | null => {
  const regex = /[0-9]/g;
  const valid = regex.test(control.value);
  return valid ? null : { number: true };
}

const upperCaseValidator = (control: AbstractControl): ValidationErrors | null => {
  const regex = /[A-Z]/g;
  const valid = regex.test(control.value);
  return valid ? null : { upperCase: true };
}

const lowerCaseValidator = (control: AbstractControl): ValidationErrors | null => {
  const regex = /[a-z]/g;
  const valid = regex.test(control.value);
  return valid ? null : { lowerCase: true };
}
