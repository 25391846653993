import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/utils/http-service.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(private http: HttpService) { }

  resetPassword(data) {
    return this.http.sendPatch('editarSenha', data);
  }
}
