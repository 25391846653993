import { AfterViewInit, Component, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { DeltaConverterService } from './delta-converter.service';

@Component({
  selector: 'quill-delta-editor',
  templateUrl: './quill-delta-editor.component.html',
  styleUrls: ['./quill-delta-editor.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QuillDeltaEditorComponent),
    multi: true
  }]
})
export class QuillDeltaEditorComponent implements AfterViewInit, ControlValueAccessor {

  @ViewChild('editor') editor: QuillEditorComponent;
  @Input() control: FormControl;
  @Input() titulo: string;
  @Input() icone: string;
  @Input() placeholder: string = "";
  @Input() loading: boolean;
  @Input() disabled: boolean;

  delta = {};

  constructor(private detlaConverter: DeltaConverterService) { }

  ngAfterViewInit(): void {
  }

  onContentChange(event: any) {
    if(event.content)
      this.onChange(event.content)
  }

  onChange = (content: any) => {};
  onTouched = () => {};

  writeValue(content: any): void {
    if (content?.ops) {
      this.control.setValue(content);
    } else if (typeof content === 'string') {
      // this.control.setValue(this.deltaConverter.html2Delta(content));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
