import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-loading',
  templateUrl: './input-loading.component.html',
  styleUrls: ['./input-loading.component.css']
})
export class InputLoadingComponent implements OnInit {

  @Input() isLoading:boolean;
  @Input() legacyInput:boolean;
  @Input() relativePosition:boolean;
  @Input() label:string;

  constructor() { }

  ngOnInit(): void {
  }

}
