<div mat-dialog-title id="header">
  <span class="ml-2">Servidor</span>
</div>
<div mat-dialog-content>
  <div id="form-servidor" class="mb-3">
    <div class="row">
      <div class="col-12">
        <mat-label id="lbl-cpf"><b>CPF:</b> {{data?.servidor?.cpf}}</mat-label>
      </div>
      <div class="col-12">
        <mat-label id="lbl-nome"><b>Nome:</b> {{data?.servidor?.nome}}</mat-label>
      </div>
    </div>
  </div>
  <div id="table-vinculos">
    <div class="row">
      <app-table
        class="w-100"
        [displayedColumns]="displayedColumns"
        [columns]="columns"
        [data]="tableData"
        [activeRowClick]="true"
        (elementClicked)="vinculoSelected($event)"
        [pageSizeOptions]="pageSizeOptions"
      ></app-table>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="null" color="primary">
    OK
  </button>
</div>
