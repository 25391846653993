import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  userForm: UntypedFormGroup;
  submitted = false;
  isProgress: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private router: Router,
    private snackBar: SnackBarService
  ) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      login: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      password: ['', [Validators.required]]
    })
  }

  // convenience getter for easy access to form fields
  get formControls() { return this.userForm.controls; }

  async onSubmit() {
    if (this.userForm.valid) {
      this.isProgress = true;
      await this.loginService.autenticar(this.userForm.value.login, this.userForm.value.password).then((ret) => {
        this.setDadosUsuario(ret);
        this.setModulos(ret);
        this.setTokenUser(ret);
        this.isProgress = false;
        this.snackBar.showSnackBarSuccess('usuário autenticado');
        this.router.navigateByUrl('/modulos');
      },
        (error) => {
          this.isProgress = false;
          console.error(error);
          if (error.error.error === 'Nao cadastrado')
            this.snackBar.showSnackBarError('Usuário ou senha incorreto');
          else if (error.error.error === 'Não autorizado')
            this.snackBar.showSnackBarError('Usuário ou senha incorreto');
          else
            this.snackBar.showSnackBarError('Sistema indisponível. Tente novamente mais tarde.');
        })
    }
  }

  setDadosUsuario(data) {
    localStorage.setItem('user', JSON.stringify(data.modulos[0]));
  }

  setModulos(data) {
    let mods: any = [];
    data.modulos.forEach(element => {
      mods.push({
        "id_modulo": element.id_modulo,
        "desc_modulo": element.desc_modulo,
        "sigla": element.sigla
      })
    });
    localStorage.setItem('modulos', JSON.stringify(mods));
  }

  setTokenUser(data) {
    localStorage.setItem('token', JSON.stringify(data.token));
  }

  reenviarSenha() {
    if (this.userForm.controls.login.valid) {
      const cpfDigitado = this.userForm.controls.login.value;
      this.isProgress = true;
      this.loginService.reenviarSenha(cpfDigitado)
        .then(ret => {
          this.snackBar.showSnackBarInfo(ret.toString())
          this.isProgress = false;
        })
        .catch(err => {
          this.snackBar.showSnackBarError(err.error.error);
          this.isProgress = false;
        });
    }
  }

}
