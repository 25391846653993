<mat-form-field
  [appearance]="appearance ? appearance : 'outline'"
  [class]="inputClasses ? inputClasses : ''"
>
  <mat-icon
    *ngIf="toolTip"
    matPrefix
    [matTooltip]="toolTip"
    matTooltipClass="example-tooltip-conf"
    matTooltipPosition="{{ tooltipPosition ? tooltipPosition : 'after' }}"
    >help</mat-icon
  >
  <input
    matInput
    mask="000.000.000-00"
    type="text"
    placeholder="000.000.000-00"
    [formControl]="cpfForm"
    #input
    (blur)="onBlur()"
    (keyup)="keyPressSubmit($event)"
  />
  <mat-label>{{ label ? label : "Servidor por CPF" }}</mat-label>
  <button
    matIconSuffix
    mat-icon-button
    aria-label="Buscar servidor"
    class="bg-light mr-1"
    (click)="getServidor(cpfForm.value)"
    [disabled]="disabled"
    matTooltip="{{ buttonTooltip ? buttonTooltip : 'Incluir servidor' }}"
    matTooltipClass="example-tooltip-conf"
    matTooltipPosition="above"
    required
  >
    <mat-icon>{{ icon ? icon : "search" }}</mat-icon>
  </button>
  <mat-error *ngIf="cpfForm.hasError('required') && isFocused(input)"
    >Campo Obrigatório *</mat-error
  >
  <mat-error
    *ngIf="!cpfForm.hasError('required') && cpfForm.hasError('invalidCPF')"
    >CPF inválido</mat-error
  >
  <app-input-loading [isLoading]="isProgress"></app-input-loading>
</mat-form-field>
