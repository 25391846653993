import { HttpService } from './http-service.service';

export class CrudService{

  constructor(private httpService: HttpService, private endPoint: string) {}

  salvar(data:any){
    return this.httpService.sendPost(this.endPoint, data);
  }

  update(id:number, data: any){
    return this.httpService.sendPatch(`${this.endPoint}/${id}`, data);
  }

  getAll(){
    return this.httpService.sendGet(this.endPoint);
  }

  getById(id: string){
    return this.httpService.sendGet(`${this.endPoint}/${id}`);
  }




}
