import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'tac', loadChildren: () => import('./modules/tac/tac.module').then(m => m.TacModule) },
  { path: 'tac2', loadChildren: () => import('./modules/tac2/tac2.module').then(m => m.Tac2Module) },
  { path: 'pad', loadChildren: () => import('./modules/pad/pad.module').then(m => m.PadModule) },
  { path: 'tca', loadChildren: () => import('./modules/tca/tca.module').then(m => m.TcaModule) },
  { path: 'relatorio', loadChildren: () => import('./modules/relatorio/relatorio.module').then(m => m.RelatorioModule) },
  { path: 'sind', loadChildren: () => import('./modules/sindicancia/sindicancia.module').then(m => m.SindicanciaModule) },
  { path: 'api', loadChildren: () => import('./modules/api/api.module').then(m => m.ApiModule) },
  { path: 'med', loadChildren: () => import('./modules/mediacao/mediacao.module').then(m => m.MediacaoModule) },
  { path: 'user', loadChildren: () => import('./modules/usuario/usuario.module').then(m => m.UsuarioModule) },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterRoutingModule { }
