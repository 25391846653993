<div class="bg-light rounded border overflow-hidden mb-3">
  <div class="d-flex align-items-center bg-secondary justify-content-between text-light p-2">
    <div class="d-flex">
      <span class="material-symbols-outlined">
        {{icone}}
      </span>
      <h5 class="ml-3 mb-0"> {{titulo}}</h5>
    </div>
    <div class="d-flex gap-3">
      <ng-content select="[title-area]"></ng-content>
    </div>
  </div>
  <div class="row pr-3 pl-3 pt-3">
    <ng-content></ng-content>
  </div>
</div>
