import { Injectable } from "@angular/core";
import { HttpService } from "../utils/http-service.service";

@Injectable({
  providedIn: 'root'
})
export class RhNetService  {

  constructor(private service: HttpService) {
  }

  getServidorByCPF(params){
    return this.service.sendGet(`admin/servidoresRHNet/${params.cpf}`);
  }
}
