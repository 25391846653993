import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  async autenticar(login, password) {

    return this.http.get(this.baseUrl + 'login', {
      params: new HttpParams().set('login', login).set('password', password)
    }).toPromise()

  }

  reenviarSenha(login) {
    return this.http.patch(this.baseUrl + `gerarNovaSenha?login=${login}`, '').toPromise();
  }
  verificarToken() {

  }

  getTokenUsuario() {
    return JSON.parse(localStorage.getItem('token'));
  }

  getTokenPerfil() {
    return JSON.parse(localStorage.getItem('tokenPerfil'));
  }

  getHeaderComTokenPerfil() {
    return {
      'Authorization': 'Bearer ' + this.getTokenPerfil(),
      'rejectUnauthorized': 'false'
    }
  }

  getHeaderComTokenUsuario() {
    return {
      'Authorization': 'Bearer ' + this.getTokenUsuario(),
      'rejectUnauthorized': 'false'
    }
  }

  getPerfil() {
    return JSON.parse(localStorage.getItem('perfil'));
  }

  getIdPerfil() {
    return JSON.parse(localStorage.getItem('perfil')).id_perfil;
  }

  getCodgPerfil() {
    return JSON.parse(localStorage.getItem('perfil')).codg_perfil;
  }

  getCodUnidadeAdministrativa() {
    return JSON.parse(localStorage.getItem('perfil')).cod_unidade_administrativa_sei;
  }

  clearLocalStorageLogOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenPerfil');
    localStorage.removeItem('perfil');
    localStorage.removeItem('user');
  }
  canActivate() {
    if (localStorage.getItem('token') != null && localStorage.getItem('user') != null) {
      return true;
    } else {
      return false;
    }
  }
}
