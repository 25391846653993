import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-frame',
  templateUrl: './form-frame.component.html',
  styleUrls: ['./form-frame.component.css']
})
export class FormFrameComponent implements OnInit {

  @Input() titulo: string;
  @Input() icone: string;

  constructor() { }

  ngOnInit(): void {
  }

}
