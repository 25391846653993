<div class="row">
  <div class="col-12">
    <mat-form-field appearance="outline" class="filter-search">
      <mat-label
        >Informe o texto para pesquisa (no caso de CPF, informe somente
        números)</mat-label
      >
      <input matInput [formControl]="defaultFilterForm" placeholder="Ex.:..." />
      <app-input-loading [isLoading]="defaultFilterLoading"></app-input-loading>
      <button
        matIconSuffix
        mat-icon-button
        class="mr-1"
        (click)="openDialog($event)"
      >
        <mat-icon>tune</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="chips d-flex align-items-start flex-wrap">
    <ng-container *ngFor="let form of filters">
      <mat-chip-listbox
        *ngIf="
          form.type === 'select-multiple' &&
          !compareArrays(form.defaultFilter, filterForm.value[form.name]) &&
          filterForm.value[form.name].length > 0
        "
        class="chip-listbox bg-light rounded-pill border pl-2 pr-2 pt-1 pb-1 m-2"
      >
        <span class="chip-listbox-title d-inline p-2">{{ form.label }}: </span>
        <mat-chip
          *ngFor="let chip of filterForm.value[form.name]"
          (removed)="
            removeSelectMultipleChip(filterForm.controls[form.name], chip)
          "
          [ngStyle]="{
            'background-color': getChipColor(chip, form.options) + '55'
          }"
          [value]="chip"
          >{{ getChipLabel(chip, form.options)
          }}<button matChipRemove aria-label="Remover filtro">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-listbox>
      <mat-chip-listbox
        *ngIf="
          form.type === 'date-range' &&
          (form.defaultFilter.inicio !== filterForm.value[form.name].inicio ||
            form.defaultFilter.fim !== filterForm.value[form.name].fim)
        "
        class="chip-listbox bg-light rounded-pill border pl-2 pr-2 pt-1 pb-1 m-2"
      >
        <span class="chip-listbox-title d-inline p-2">{{ form.label }}</span>
        <ng-container
          *ngIf="
            form.defaultFilter.inicio !== filterForm.value[form.name].inicio
          "
        >
          <span class="chip-listbox-title d-inline pb-2 pt-2">de: </span>
          <mat-chip
            (removed)="
              removeChip(filterForm.get(form.name).get('inicio'), form.defaultFilter.inicio)
            "
            [value]="filterForm.value[form.name]"
            >{{ formatChipData(filterForm.value[form.name].inicio)
            }}<button matChipRemove aria-label="Remover filtro">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </ng-container>

        <ng-container
          *ngIf="form.defaultFilter.fim !== filterForm.value[form.name].fim"
        >
          <span
            class="chip-listbox-title d-inline pb-2 pt-2"
            [ngClass]="{
              'pl-2':
                form.defaultFilter.inicio !== filterForm.value[form.name].inicio
            }"
            >até:
          </span>
          <mat-chip
            (removed)="
              removeChip(filterForm.get(form.name).get('fim'), form.defaultFilter.fim)
            "
            [value]="filterForm.value[form.name]"
            >{{ formatChipData(filterForm.value[form.name].fim)
            }}<button matChipRemove aria-label="Remover filtro">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </ng-container>
      </mat-chip-listbox>
      <mat-chip-listbox
        *ngIf="
          form.type === 'date' &&
          form.defaultFilter !== filterForm.value[form.name]
        "
        class="chip-listbox bg-light rounded-pill border pl-2 pr-2 pt-1 pb-1 m-2"
      >
        <span class="chip-listbox-title d-inline p-2">{{ form.label }}: </span>
        <mat-chip
          (removed)="
            removeChip(filterForm.controls[form.name], form.defaultFilter)
          "
          [value]="filterForm.value[form.name]"
          >{{ formatChipData(filterForm.value[form.name])
          }}<button matChipRemove aria-label="Remover filtro">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-listbox>
    </ng-container>
    <button mat-button class="clear-filters-btn m-3 rounded-pill border allow-lowercase pr-3 pl-3" (click)="clearFilters()">Limpar filtros</button>
  </div>
</div>
