import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/authentication/login/login.service';
import { ResetPasswordComponent } from 'src/app/authentication/reset-password/reset-password.component';
import { MatOverlayService } from 'src/app/services/mat-overlay.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  perfil: any;
  primeiroNome = "Usuário";

  constructor(
    private loginService: LoginService,
    private router: Router,
    private dialog: MatDialog,
    private overlayService: MatOverlayService

  ) { }

  ngOnInit(): void {
    this.perfil = this.loginService.getPerfil();
    this.primeiroNome = this.perfil.nome.split(' ')[0];
  }

  logOut() {
    this.closeOverlay();
    this.loginService.clearLocalStorageLogOut();
    this.router.navigate(['/']);
  }

  openResetPasswordDialog() {
    this.closeOverlay();
    this.dialog.open(ResetPasswordComponent, {
      width: '400px',
    });
  }

  closeOverlay() {
    this.overlayService.close();
  }
}
