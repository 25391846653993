import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/utils/crud.service';
import { HttpService } from 'src/app/utils/http-service.service';

@Injectable({
  providedIn: 'root'
})
export class EmitirDeclaracoesService {

  constructor(private service: HttpService) {
  }

  previewDeclaracao(data:any){
    return this.service.sendGet("tac/declaracao/preview", data, "html");
  }

  downloadDeclaracao(data: any){
    return this.service.sendGet("tac/declaracao/download", data);
  }
}
