import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { LoginService } from "src/app/authentication/login/login.service";
import { SnackBarService } from "../services/snack-bar.service";

export const AuthenticationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  if(inject(LoginService).canActivate()){
    return true;
  } else {
    inject(SnackBarService).showSnackBarInfo('Acesso negado. Faça login para acessar o sistema.');
    return inject(Router).createUrlTree(['/']);
  }
}
