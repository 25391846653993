import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import PadAnexo from 'src/app/modules/pad/Models/PadAnexo';
import { HttpService } from 'src/app/utils/http-service.service';

@Injectable({
  providedIn: 'root'
})
export class AnexoService {

  constructor(private service: HttpService) { }

  upload(anexos, params) {
    return this.service.sendPost('admin/anexos', anexos, params).toPromise();
  }
  download(id: number): Observable<Blob> {
    return this.service.sendGet(`admin/anexos/${id}/download`, {}, "blob");
  }
  sendAnexos(data: any, params: any) {
    return this.service.sendPost(`admin/anexos`, data, params);
  }

  sendPadAnexos(id: number, data: any, params: any) {
    return this.service.sendPost(`pad/pads/${id}/anexos`, data, params);
  }
  sendSeiPadAnexo(padId: number, id: number) {
    return this.service.sendPatch(`pad/pads/${padId}/anexos/${id}`, {});
  }
  getPadAnexos(padId): Observable<PadAnexo[]> {
    return this.service.sendGet(`pad/pads/${padId}/anexos`);
  }
  downloadPadAnexo(padId, idAnexo): Observable<Blob> {
    return this.service.sendGet(`pad/pads/${padId}/anexos/${idAnexo}/download`, {}, "blob");
  }
  deletePadAnexo(padId: number, idAnexo: number): Observable<boolean> {
    return this.service.sendDelete(`pad/pads/${padId}/anexos/${idAnexo}`)
  }

}
