import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { Enquadramento } from 'src/app/modules/admin/models/Enquadramento';
import { EnquadramentoSelectDialogComponent } from './enquadramento-select-dialog/enquadramento-select-dialog.component';
import { EnquadramentoService } from './enquadramento-select.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-enquadramento-select',
  templateUrl: './enquadramento-select.component.html',
  styleUrls: ['./enquadramento-select.component.css']
})
export class EnquadramentoSelectComponent implements OnInit, OnChanges, OnDestroy {

  private destroyed$ = new Subject<void>();
  enquadramentos: Enquadramento[] = [];
  loading: boolean = false;
  @Input() formInput: AbstractControl = new UntypedFormControl(null);
  @Input() disabled: boolean = false;
  @Input() unique: boolean = false;
  @Input() idFilter: null | number[] = null;
  @Input() forTac: boolean = false;

  constructor(
    private enquadramentoService: EnquadramentoService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.formInput.disable();
    this.enquadramentoService.getEnquadramentos(this.forTac)
      .pipe(take(1))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (enquadramentos: Enquadramento[]) => {
          this.loading = false;
          this.formInput.enable();
          this.enquadramentos = this.idFilter ?
            enquadramentos.filter(e => this.idFilter.some(id => e.id === id)) :
            enquadramentos;
        },
        error: error => {
          this.formInput.enable();
          this.loading = false;
          console.error(error);
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idFilter && changes.idFilter.currentValue) {
      this.enquadramentos = this.enquadramentos.filter(e => changes.idFilter.currentValue.some(id => e.id === id));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  deleteTransgressao(tr) {
    const selectedEnquadramentos = this.formInput.value;
    const index = selectedEnquadramentos.indexOf(tr);
    selectedEnquadramentos.splice(index, 1)
    this.formInput.setValue(selectedEnquadramentos);
  }
  compareWithTransgressao(o1: any, o2: any) {
    return o1.id === o2?.id;
  }
  openDialog() {
    const dialogRef = this.dialog.open(EnquadramentoSelectDialogComponent, {
      maxHeight: '95%',
      width: '95%',
      panelClass: 'overflow-auto',
      data: { form: this.formInput, enquadramentos: this.enquadramentos, unique: this.unique, forTac: this.forTac }
    });
  }
}
