<mat-card class="fade-in profile-card">
  <mat-card-content>
    <div class="d-flex gap-3 mb-3 justify-content-between">
      <h5 class="mt-2">Olá, {{ primeiroNome }}!</h5>
      <button mat-icon-button aria-label="Sair" color="warn" matTooltip="Sair" matTooltipClass="fs-12" matTooltipPosition="below" (click)="logOut()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
    <div class="d-flex flex-column mb-3 align-items-start text-secondary fs-10">
      <span>{{ perfil.lotacao }}</span>
      <span>Unidade SEI {{ perfil.cod_unidade_administrativa_sei }}</span>
      <span>perfil {{ perfil.desc_perfil }}</span>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-stretch">
      <button mat-button (click)="closeOverlay()" routerLink="/modulos">Seus módulos</button>
      <button mat-button (click)="openResetPasswordDialog()">Alterar Senha</button>
    </div>
  </mat-card-content>
</mat-card>
