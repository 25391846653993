<h3 mat-dialog-title>Alterar Senha</h3>
<div mat-dialog-content [formGroup]="resetPasswordForm">
  <mat-form-field appearance="outline" class="pb-3 pt-3">
    <mat-label>Nova senha</mat-label>
    <input matInput type="password" formControlName="senhaNova" required>
    <mat-error *ngIf="resetPasswordForm.get('senhaNova').touched && resetPasswordForm.get('senhaNova').hasError('required')">Campo obrigatório</mat-error>
    <mat-error *ngIf="resetPasswordForm.get('senhaNova').touched && !resetPasswordForm.get('senhaNova').hasError('required') && resetPasswordForm.get('senhaNova').invalid">{{getErrorText()}}</mat-error>
  </mat-form-field>
  <!-- <span class="fs-10 text-secondary">A senha precisa ter pelo menos um caractere especial, um número, uma letra maiúscula uma e minúscula</span> -->
  <mat-form-field appearance="outline" class="pb-3 pt-3">
    <mat-label>Confirmar nova senha</mat-label>
    <input matInput type="password" formControlName="confirmarSenha" required>
    <mat-error>A confirmação deve ser igual a nova senha.</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions class="d-flex gap-3 p-3">
  <button mat-raised-button [mat-dialog-close]="null">Cancelar</button>
  <button mat-raised-button color="primary" (click)="editarSenha()">Alterar</button>
</div>
