import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy, HostListener } from '@angular/core';

@Component({
  selector: 'app-resizable-container',
  templateUrl: './resizable-container.component.html',
  styleUrls: ['./resizable-container.component.css']
})
export class ResizableContainerComponent implements OnInit, AfterViewInit {

  @ViewChild('resizable') container: ElementRef;
  width: number = 400;
  oldWidth:number = 0
  grabber = false;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabber) {
        return;
    }
    this.resizer(event.clientX - this.oldWidth);
    this.oldWidth = event.clientX;
  }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.grabber = false;
  }

  onMouseDown(event: MouseEvent) {
    this.grabber = true;
    this.oldWidth = event.clientX;
    event.preventDefault();
  }
  resizer(offsetY: number) {
    this.width += offsetY;
  }

  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // this.width = this.container.nativeElement.getBoundingClientRect().width;
  }

}
