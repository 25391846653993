import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import jsPDF from 'jspdf';

interface dialogData {
  content: string;
  fileName?: string;
}
@Component({
  selector: 'app-export-html',
  templateUrl: './export-html.component.html',
  styleUrls: ['./export-html.component.css']
})
export class ExportHTMLComponent implements AfterViewInit {

  @ViewChild('content') contentDiv: ElementRef<HTMLElement>;

  constructor(
    public dialogRef: MatDialogRef<ExportHTMLComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dialogData) { }

  ngAfterViewInit(): void {
    this.contentDiv.nativeElement.innerHTML = this.data.content;
  }
  exportWord() {

  }
  exportPDF() {
    const doc = new jsPDF();
    const data = this.data;
    doc.html(this.contentDiv.nativeElement.innerHTML, {
      callback: (doc) => {
        // Save the PDF
        doc.setFont('arial')
        doc.save(data.fileName ? data.fileName : 'document.pdf');
      },
      autoPaging: 'text',
      margin: [8, 8, 8, 8],
      width: 170, //target width in the PDF document
      windowWidth: 640 //window width in CSS pixels
    });
  }

}
