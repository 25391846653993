<div class="header" [ngClass]="{'p-2': !mini}">
    <div class="back-btn w-100">
        <ng-content select="[back-btn]"></ng-content>
    </div>
    <div class="p-2" *ngIf="titulo">
        <h3>{{titulo}}</h3>
        <h5 class="text-secondary" *ngIf="subTitulo">{{subTitulo}}</h5>
    </div>
    <div class="side-content">
        <ng-content></ng-content>
    </div>
    <div class="menu w-100">
        <div class="left-content">
            <ng-content select="[left-content]"></ng-content>
        </div>
        <div class="right-content">
            <ng-content select="[right-content]"></ng-content>
        </div>
      </div>
    <div class="header-loading">
      <ng-content select="[header-loading]"></ng-content>
    </div>
</div>
