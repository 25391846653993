<button
  mat-button
  *ngIf="item.type === 'button'"
  class="nav-link text-light"
  routerLink="{{ item.link }}"
  routerLinkActive="active"
>
  {{ item.nome }}
</button>

<a
  mat-button
  *ngIf="item.type === 'link'"
  [href]="item.link"
  [target]="item.target"
  rel="noopener noreferrer"
  class="nav-link text-light"
  >{{ item.nome }}</a
>

<ng-container *ngIf="item.type === 'menu'">
  <button
    mat-button
    [matMenuTriggerFor]="menu"
    class="nav-link text-light pr-1"
  >
    <span>{{ item.nome }}</span>
    <mat-icon class="drop-arrow">expand_more</mat-icon>
  </button>
  <mat-menu #menu>
    <ng-container *ngFor="let menuItem of item.menuItems">
      <button
        *ngIf="!menuItem.type"
        mat-menu-item
        routerLink="{{ menuItem.link }}"
        routerLinkActive="active"
      >
        {{ menuItem.nome }}
      </button>
      <a
        mat-menu-item
        *ngIf="menuItem.type === 'link'"
        [href]="menuItem.link"
        [target]="menuItem.target"
        rel="noopener noreferrer"
        class="nav-link"
        >{{ menuItem.nome }}</a
      >
    </ng-container>
  </mat-menu>
</ng-container>
