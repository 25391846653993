import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-novo-agente-dialog',
  templateUrl: './novo-agente-dialog.component.html',
  styleUrls: ['./novo-agente-dialog.component.css']
})
export class NovoAgenteDialogComponent implements OnInit{
  constructor(
    private matIconReg: MatIconRegistry
  ) { }

  ngOnInit(): void {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }
}
