import { Component, Inject, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.css']
})
export class DialogsComponent implements OnInit {

  @Input() title;
  @Input() msg;
  constructor() {}

  ngOnInit(): void {
  }
}
