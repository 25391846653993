import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/authentication/login/login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  sendPost<T>(endpoint: string, data: any, params?:any, resType:any = "json"): Observable<T> {
    return this.http.post<T>(this.baseUrl + endpoint, data, {
      params: params,
      headers: new HttpHeaders(this.loginService.getHeaderComTokenUsuario()),
      responseType: resType
    })
  }

  sendPut<T>(endpoint: string, data: any): Observable<T> {
    return this.http.put<T>(this.baseUrl + endpoint, data, {
      headers: new HttpHeaders(this.loginService.getHeaderComTokenUsuario())
    })
  }

  sendGet<T>(endpoint: string, params: any = {}, resType:any = "json"): Observable<T> {
    return this.http.get<T>(this.baseUrl + endpoint, {
      headers: new HttpHeaders(this.loginService.getHeaderComTokenUsuario()),
      params: params,
      responseType: resType
    })
  }

  sendPatch<T>(endpoint: string, data: any): Observable<T> {
    return this.http.patch<T>(this.baseUrl + endpoint, data, {
      headers: new HttpHeaders(this.loginService.getHeaderComTokenUsuario()),
    })
  }

  sendDelete<T>(endpoint: string, params: {} = {}): Observable<T> {
    return this.http.delete<T>(this.baseUrl + endpoint, {
      headers: new HttpHeaders(this.loginService.getHeaderComTokenUsuario()),
      params
    })
  }

}
