import { Injectable } from "@angular/core";
import { CrudService } from "src/app/utils/crud.service";
import { HttpService } from "src/app/utils/http-service.service";

@Injectable({
    providedIn: 'root'
})
export class EnquadramentoService extends CrudService{
    constructor(private service: HttpService){
        super(service, "enquadramento")
    }
    getArtigos(leisAntigas = false){
      const params = leisAntigas ? {} : {'filter[leisAntigas]': 0}
      return this.service.sendGet('admin/artigos', params);
    }
    getEnquadramentos(leisAntigas = false){
      const params = {
        'filter[ativa]': 1,
      }
      if(!leisAntigas)
        Object.assign(params, {'filter[leisAntigas]': 0})
      return this.service.sendGet('admin/transgressoes', params);
    }
}
