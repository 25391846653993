export enum Perfil {
  ADMINISTRADOR = 1,
  GERENCIADOR = 2,
  COMISSAO = 3,
  PORTARIA = 4,
  JULGAMENTO = 6,
  CONSULTA = 7,
  DECLARACAO = 8,
  EXECUTOR = 9,
  CORREGEDOR = 10,
  JULGADOR = 11,
}
