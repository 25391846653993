<div mat-dialog-title>Editar Servidor</div>
<div [formGroup]="servidorForm" mat-dialog-content>
  <div class="row mt-3">
    <div class="col-5">
      <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          placeholder="CPF"
          disabled
          [value]="servidor ? helper.addCPFLashes(servidor.cpf) : ''"
          required
        />
        <mat-label>CPF</mat-label>
        <app-input-loading [isLoading]="loading"></app-input-loading>
      </mat-form-field>
    </div>
    <div class="col-7">
      <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          placeholder="Nome"
          formControlName="nome"
          required
        />
        <mat-label>Nome</mat-label>
        <app-input-loading [isLoading]="loading"></app-input-loading>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-7">
      <mat-form-field appearance="outline">
        <input
          matInput
          type="email"
          placeholder="Email"
          formControlName="email"
          required
        />
        <mat-label>Email</mat-label>
        <mat-error *ngIf="servidorForm.get('email').touched && servidorForm.get('email').hasError('invalidemail')">Email inválido</mat-error>
        <mat-error *ngIf="servidorForm.get('email').touched && servidorForm.get('email').hasError('required')">Campo obrigatório</mat-error>
        <app-input-loading [isLoading]="loading"></app-input-loading>
      </mat-form-field>
    </div>
    <div class="col-5">
      <mat-form-field appearance="outline">
        <input
          matInput
          type="phone"
          placeholder="Telefone"
          formControlName="telefone"
          [mask]="servidorForm.get('telefone').value?.length <= 10 ? '(00) 0000-0*' : '(00) 0 0000-0000'"
          [validation]="false"
          required
        />
        <mat-error>Campo obrigatório</mat-error>
        <mat-label>Telefone</mat-label>
        <app-input-loading [isLoading]="loading"></app-input-loading>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Origem</mat-label>
        <input
          matInput
          type="text"
          placeholder="Origem"
          formControlName="origem"
        />
        <app-input-loading [isLoading]="loading"></app-input-loading>
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Orgão Lotação</mat-label>
        <mat-select
          formControlName="orgaoLotacao"
          [compareWith]="compareWithOrgao"
          required
        >
          <mat-option [value]="a" *ngFor="let a of orgaos"
            >{{ a.sigla }} - {{ a.nome }}</mat-option
          >
        </mat-select>
        <mat-error>Campo Obrigatório</mat-error>
        <app-input-loading [isLoading]="orgaosLoading"></app-input-loading>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <mat-form-field appearance="outline">
        <mat-select placeholder="Vinculo" formControlName="vinculo" required>
          <mat-option value="CEDIDO">Cedido</mat-option>
          <mat-option value="TERCEIRIZADO">Terceirizado</mat-option>
        </mat-select>
        <mat-label>Vinculo</mat-label>
        <app-input-loading [isLoading]="loading"></app-input-loading>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="outline">
        <mat-label> Data de nascimento</mat-label>
        <input
          matInput
          [matDatepicker]="dataNascimento"
          placeholder="00/00/0000"
          formControlName="dataNascimento"
          required
        />
        <mat-datepicker-toggle
        matIconSuffix
          [for]="dataNascimento"
        ></mat-datepicker-toggle>
        <mat-datepicker #dataNascimento></mat-datepicker>
        <mat-error>Campo Obrigatório</mat-error>
        <app-input-loading [isLoading]="loading"></app-input-loading>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="outline">
        <mat-label>Sexo</mat-label>
        <mat-select formControlName="genero">
          <mat-option value="MASCULINO">Masculino</mat-option>
          <mat-option value="FEMININO">Feminino</mat-option>
        </mat-select>
        <app-input-loading [isLoading]="loading"></app-input-loading>
      </mat-form-field>
    </div>
  </div>


  <div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="false" class="mr-3">
      Cancelar
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="closeDialog()"
      [disabled]="servidorForm.invalid"
    >
      Salvar
    </button>
  </div>
</div>
