import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Servidor from 'src/app/modules/admin/models/Servidor';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { HelperService } from 'src/app/utils/helper.service';
import { ServidorService } from '../servidor.service';
import { AdminService } from 'src/app/modules/admin/admin.service';

@Component({
  selector: 'app-edit-servidor',
  templateUrl: './edit-servidor.component.html',
  styleUrls: ['./edit-servidor.component.css']
})
export class EditServidorComponent implements OnInit {

  servidorForm: FormGroup;
  orgao = false;
  loading = false;

  orgaos = [];
  orgaosLoading = false;
  servidor : Servidor;

  constructor(
    public dialogRef: MatDialogRef<EditServidorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private servidorService: ServidorService,
    private adminService: AdminService,
    public helper: HelperService,
    private snackBar: SnackBarService,
    private fb: FormBuilder
  ) { }
  ngOnInit(): void {
    this.servidorForm = this.fb.group({
      cpf: [{ value: null, disabled: true }],
      vinculo: [null, Validators.required],
      nome: [null, Validators.required],
      dataNascimento: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      telefone: ['', Validators.required],
      genero: [null, Validators.required],
      origem: [null, Validators.required],
      orgaoLotacao: [null, Validators.required],
    });
    this.getOrgaoList();
    this.servidorService.buscaServidor(this.data)
      .subscribe((res: Servidor) => {
        this.servidor = res;
        this.loadServidorData(this.servidor);
        this.loading = false;
      },
        error => {
          this.loading = false;
          this.snackBar.showSnackBarError('Erro ao carregar dados do servidor');
          console.error(error);
          this.dialogRef.close();
        })
  }
  getOrgaoList() {
    this.orgaosLoading = true;
    this.servidorForm.controls.orgaoLotacao.disable();
    this.adminService.listarOrgaos()
      .subscribe((orgaos: any[]) => {
        this.orgaos = orgaos;
        this.orgaosLoading = false;
        this.servidorForm.controls.orgaoLotacao.enable();
      },
        error => {
          console.error(error);
          this.orgaosLoading = false;
          this.servidorForm.controls.orgaoLotacao.enable();
        });
  }
  compareWithOrgao(o1: any, o2: any) {
    return o1.id === o2;
  }
  loadServidorData(data) {
    this.servidorForm.patchValue(data);
  }

  closeDialog() {
    this.dialogRef.close({
      id: this.servidor.id,
      cpf: this.servidor.cpf,
      nome: this.servidorForm.value.nome,
      dataNascimento: this.helper.formatarData(this.servidorForm.value.dataNascimento),
      email: this.servidorForm.value.email,
      telefone: this.servidorForm.value.telefone,
      genero: this.servidorForm.value.genero,
      vinculo:
        {
          tipo: this.servidorForm.value.vinculo,
          orgaoLotacaoId: this.servidorForm.value.orgaoLotacao.id,
          origemNome: this.servidorForm.value.origem,
        }
    });
  }
}
