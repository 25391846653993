<h1 mat-dialog-title>Validação {{ data.documento }}</h1>
<div mat-dialog-content class="validacao-container">
  <p *ngIf="data.msg.length === 0">{{ data.documento }} é válido(a)</p>
  <p *ngFor="let line of data.msg">{{ line }}</p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true" color="primary">
    {{ data.confirmLabel ? data.confirmLabel : "Confirmar" }}
  </button>
</div>
