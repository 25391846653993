import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { HelperService } from 'src/app/utils/helper.service';

@Component({
  selector: 'app-add-servidor',
  templateUrl: './add-servidor.component.html',
  styleUrls: ['./add-servidor.component.css']
})
export class AddServidorComponent implements OnInit {

  servidorForm: UntypedFormGroup;
  orgao = false;
  loading = false;

  orgaos = [];
  orgaosLoading = false;

  constructor(
    public dialogRef: MatDialogRef<AddServidorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helper: HelperService,
    private adminService: AdminService,
    private fb: UntypedFormBuilder
  ) { }
  ngOnInit(): void {
    this.servidorForm = this.fb.group({
      cpf: [{ value: this.helper.addCPFLashes(this.data.cpf), disabled: true }],
      vinculo: [null, Validators.required],
      nome: [null, Validators.required],
      dataNascimento: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      telefone: ['', Validators.required],
      genero: [null, Validators.required],
      origem: [null, Validators.required],
      orgaoLotacao: [null, Validators.required],
    });
    this.getOrgaoList();
  }
  getOrgaoList() {
    this.orgaosLoading = true;
    this.servidorForm.controls.orgaoLotacao.disable();
    this.adminService.listarOrgaos()
      .subscribe((orgaos: any[]) => {
        this.orgaos = orgaos;
        this.orgaosLoading = false;
        this.servidorForm.controls.orgaoLotacao.enable();
      },
        error => {
          console.error(error);
          this.orgaosLoading = false;
          this.servidorForm.controls.orgaoLotacao.enable();
        });
  }

  closeDialog() {
    this.dialogRef.close({
      cpf: this.data.cpf,
      nome: this.servidorForm.value.nome,
      dataNascimento: this.helper.formatarData(this.servidorForm.value.dataNascimento),
      email: this.servidorForm.value.email,
      telefone: this.servidorForm.value.telefone,
      genero: this.servidorForm.value.genero,
      vinculo:
        {
          tipo: this.servidorForm.value.vinculo,
          orgaoLotacaoId: this.servidorForm.value.orgaoLotacao.id,
          origemNome: this.servidorForm.value.origem,
        }
    });
  }
  compareWithOrgao(o1: any, o2: any) {
    return o1.id === o2;
  }
}
