import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/components/dialogs/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-validate-dialog',
  templateUrl: './validate-dialog.component.html',
  styleUrls: ['./validate-dialog.component.css']
})
export class ValidateDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     }

  ngOnInit(): void {
  }

}
