import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayOutsideClickDispatcher, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, Type } from '@angular/core';

export const OVERLAY_DATA_TOKEN = new InjectionToken<any>('OVERLAY_DATA_TOKEN');

@Injectable({
  providedIn: 'root'
})
export class MatOverlayService {
  private overlayRef: OverlayRef | null = null;
  constructor(
    private overlay: Overlay,
    private outsideClickDispatcher: OverlayOutsideClickDispatcher,
    private injector: Injector
    ) { }

  open(component: Type<any>, origin, config?: OverlayConfig, data?: any): OverlayRef {
    if (this.isOpen()) {
      this.close();
    }

    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetY: 10
        }
      ]);
    const overlayConfig: OverlayConfig = {
      positionStrategy: positionStrategy,
      hasBackdrop: false,
      ...config
    };

    this.overlayRef = this.overlay.create(overlayConfig);
    this.outsideClickDispatcher.add(this.overlayRef);

    this.overlayRef.outsidePointerEvents()
      .subscribe(event => {
        if (!this.overlayRef.overlayElement.contains(event.target as HTMLElement)) {
          this.close();
        }
      });

    const injector = Injector.create({
      providers: [
        { provide: OVERLAY_DATA_TOKEN, useValue: data }
      ],
      parent: this.injector // Assuming `this.injector` is the Injector available in your class
    });


    const componentPortal = new ComponentPortal(component, null, injector);
    this.overlayRef.attach(componentPortal);
    return this.overlayRef;
  }

  close(): void {
    if (this.overlayRef) {
      this.outsideClickDispatcher.remove(this.overlayRef);
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  isOpen(): boolean {
    return this.overlayRef?.hasAttached() || false;
  }
  addOutsideClickDispatcher(overlayRef: OverlayRef) {
    this.outsideClickDispatcher.add(overlayRef);
  }
}
