<header class="header bg-emerland">
  <div
    class="header container-4k d-flex align-items-center justify-content-between"
  >
    <div class="position-relative d-flex align-items-center">
      <img class="pl-4 pr-3" src="../../../assets/img/Logo.svg"/>
      <nav class="nav-bar">
        <ul class="nav">
          <li class="nav-item" *ngFor="let item of navItems; let i = index">
            <app-nav-item [item]="item"></app-nav-item>
          </li>
        </ul>
      </nav>
    </div>

    <div class="profile">
      <button
        mat-icon-button
        class="profile-btn text-light"
        (click)="toggleProfileOverlay($event)"
      >
        <mat-icon>account_box</mat-icon>
      </button>
    </div>

  </div>
</header>
