<div
  #resizable
  class="resizable-container container ml-0 mr-0"
  [style.width.px]="width"
>
  <ng-content></ng-content>
  <div class="bar" (mousedown)="onMouseDown($event)">
    <span class="hover"></span>
    <mat-icon class="arrow-left">chevron_left</mat-icon>
    <mat-icon class="arrow-right">chevron_right</mat-icon>
  </div>
</div>
