import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PerfilService } from "../perfil.service";
import { LoginService } from "src/app/authentication/login/login.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-modulos",
  templateUrl: "./modulos.component.html",
  styleUrls: ["./modulos.component.css"],
})
export class ModulosComponent implements OnInit {
  private URL: string = "/main/";
  modulos: [] = [];
  usuario: any;
  nomeServidor: string;

  constructor(
    private router: Router,
    private perfilService: PerfilService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    const cacheModulos = JSON.parse(localStorage.getItem("modulos"));
    const cacheServidor = JSON.parse(localStorage.getItem("user"));
    this.modulos = cacheModulos ? cacheModulos : null;
    this.nomeServidor = cacheServidor ? cacheServidor.nome : "";
  }

  async getPerfilUsuarioModulo(modulo) {
    let idModulo = modulo.id_modulo;
    let nomeModulo = modulo.sigla.toLowerCase();
    this.spinner.show();
    await this.perfilService
      .getPerfilUsuarioPorModulo(idModulo)
      .then(
        (ret: any[]) => {
          this.setPerfilModulo(ret.find((x) => x.perfilModulo.ativo));
          this.setTokenPerfil(ret.find((x) => x.perfilModulo.ativo));

          this.router.navigateByUrl(`${this.URL}${nomeModulo}`);
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => this.spinner.hide());
  }

  setPerfilModulo(data) {
    localStorage.setItem("perfil", JSON.stringify(data?.perfilModulo));
  }

  setTokenPerfil(data) {
    localStorage.setItem("tokenPerfil", JSON.stringify(data?.token));
  }

  getModuloLabel(sigla) {
    switch (sigla) {
      case "TAC":
        return "TAC";
      case "PAD":
        return "PAD";
      case "SIND":
        return "Sindicância";
      case "TCA":
        return "TCA";
      case "API":
        return "API";
      case "MED":
        return "Mediação";
      case "TAC2":
        return "TAC2";
      default:
        break;
    }
  }
  iconControlIcon(sigla) {
    switch (sigla) {
      case "TAC":
        return "handshake";
      case "PAD":
        return "gavel";
      case "SIND":
        return "search";
      case "TCA":
        return "description";
      case "API":
        return "description";
      case "MED":
        return "description";
      case "TAC2":
        return "description";
      default:
        break;
    }
  }
  iconControl(sigla) {
    return `../../../../../assets/img/${sigla.toLowerCase()}.png`;
  }
}
