import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  filters: any;
  filterForm: any;
}

@Component({
  selector: 'app-table-filter-dialog',
  templateUrl: './table-filter-dialog.component.html',
  styleUrls: ['./table-filter-dialog.component.css']
})
export class TableFilterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TableFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }

  clearFilters(){
    this.data.filters.forEach(filter => {
      this.data.filterForm.get(filter.name)?.setValue(filter.defaultFilter);
    });
  }
  ObjectKeys(obj) {
    return Object.keys(obj);
  }
}
