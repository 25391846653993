<div class="d-flex gap-3 justify-content-between align-items-start flex-wrap">
  <input
    type="file"
    (change)="setAnexo($event.target.files)"
    hidden="true"
    [accept]="allowedTypes"
    #anexo
  />
  <button
    mat-raised-button
    (click)="anexo.click()"
    type="button"
    class="p-3 mb-3 anexo-btn"
    [disabled]="disabled"
    [matTooltip]="tooltip ? tooltip : ''"
    matTooltipPosition="below"
  >
    <mat-icon>attach_file</mat-icon> {{ lbButton }}
  </button>
  <mat-error *ngIf="msgError" class="error-msg">Anexo Obrigatório*</mat-error>

  <dynamic-list class="flex-grow-1">
    <dynamic-list-item *ngFor="let anx of anexos.value">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column">
          <span *ngIf="anx.nome" class="text-dark">{{ anx.nome }}</span>
          <span *ngIf="anx.name" class="text-dark">{{ anx.name }}</span>
        </div>

        <span *ngIf="anx.size" class="text-secondary text-right">{{
          helper.formatBytes(anx?.size)
        }}</span>
        <div class="d-flex" *ngIf="anx.id">
          <button mat-button color="primary" (click)="visualizar(anx.id)">
            visualizar
          </button>
          <button
            mat-button
            color="accent"
            (click)="deletar(anx)"
            [disabled]="disabled"
          >
            excluir
          </button>
        </div>
      </div>
    </dynamic-list-item>
  </dynamic-list>
</div>

