<button
  type="button"
  class="mb-3 p-2"
  mat-raised-button
  (click)="openDialog()"
  [disabled]="loading || disabled"
>
  {{unique ? "Selecionar transgressão" : "Selecionar transgressões"}}
  <app-input-loading
    [isLoading]="loading"
    [legacyInput]="true"
  ></app-input-loading>
</button>
<h5 *ngIf="formInput.value?.length">{{unique ? "Transgressão Selecionada" : "Transgressões Selecionadas"}}</h5>
<dynamic-list>
  <dynamic-list-item *ngFor="let tr of formInput.value">
    <div class="d-flex justify-content-between align-items-start fs-11">
      <span
        >{{ tr.artigo.descricao }} - Inciso {{ tr.inciso }} -
        {{ tr.descricao }}</span
      >
      <button
        mat-icon-button
        class="mat-elevation-z2 ml-3"
        color="basic"
        (click)="deleteTransgressao(tr)"
        matTooltip="Remover Transgressão"
        matTooltipClass="example-tooltip-conf"
        matTooltipPosition="above"
        [disabled]="disabled"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </dynamic-list-item>
</dynamic-list>
