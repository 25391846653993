<ngx-spinner
type="ball-spin-clockwise-fade">
  <p style="font-size: 20px; color: white; margin-top: 40px;">Por favor, aguarde...</p>
</ngx-spinner>
<div class="master">
    <header class="header"> <app-header></app-header></header>
    <main class="main container-fluid p-0">
        <router-outlet></router-outlet>
    </main>
</div>
