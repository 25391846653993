import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor() { }

  /**
   *
   * @param {string} cpf CPF to be validated
   * @returns {boolean} true if valid, false otherwise
   */
  isCPFValido = isCPFValido;

  /**
   *
   * @param {number} a size of the file, in bytes
   * @returns {string} formatted byte string
   */
  formatBytes(a, b = 2, k = 1024): string {
    let d = Math.floor(Math.log(a) / Math.log(k));
    return 0 == a ? "0 Bytes" : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }

  /**
   *
   * @param {string} cpf A CPF string with only numbers of length 11
   * @returns {string} A CPF string with the corresponding dots and dash
   */
  addCPFLashes(cpf: string | number): string {
    if(typeof cpf === "number") cpf = cpf.toString();
    if (cpf == undefined || cpf.length !== 11)
      return
    return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(6, 9)}-${cpf.substring(9, 11)}`
  }

  /**
   *
   * @param {any} moment Date string, Date, number, or Moment (used by material datepicker component), to be formatted
   * @param {number} yearOffset Optional offset to be added to formatted date year
   * @param {number} monthOffset Optional offset to be added to formatted date month
   * @param {number} dayOffset Optional offset to be added to formatted date day
   * @returns
   */
  formatarData(moment, yearOffset?: number, monthOffset?: number, dayOffset?: number): string {
    let date = moment._d ? new Date(moment._d) : new Date(moment);

    if (yearOffset) date.setFullYear(date.getFullYear() + yearOffset);
    if (monthOffset) date.setMonth(date.getMonth() + monthOffset);
    if (dayOffset) date.setDate(date.getDate() + dayOffset);

    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    return formattedDate;
  }

  /**
   *
   * @param {AbstractControl} control Control passed by material component
   * @returns {ValidationErrors | null} Error if invalid, null if no errors
   */
  emptyArrayValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value.length === 0) return { 'empty': true };
    return null;
  }

  /**
   *
   * @param {AbstractControl} control Control passed by material component
   * @returns {ValidationErrors | null} Error if invalid, null if no errors
   */
  cpfValidator(control: AbstractControl): ValidationErrors | null {
    if (!isCPFValido(control.value)) return { 'invalidCPF': true };
    return null;
  }

  /**
   *
   * @param {object} obj An object to be cleaned
   * @returns the object reduced, without keys with null values
   */
  cleanEmpty(obj: object): object {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === "object" ? this.cleanEmpty(v) : v])
      .reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
  }

  addDiasUteis(data: Date | string, dias: number): Date {
    let dataInicial = new Date(data);
    let dataFinal: Date;
    for (let i = 0; i < dias;) {
      dataFinal = new Date(dataInicial.setDate(dataInicial.getDate() + 1));
      if (dataFinal.getDay() !== 0 && dataFinal.getDay() !== 6)
        i++;
    }
    return dataFinal;
  }
}
const isCPFValido = (cpf: string | number): boolean => {
  if(typeof cpf === "number") cpf = cpf.toString()
  if (typeof cpf !== "string") return false
  cpf = cpf.replace(/[\s.-]*/igm, '')
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  ) {
    return false
  }
  var soma = 0
  var resto
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if ((resto == 10) || (resto == 11)) resto = 0
  if (resto != parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (var i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if ((resto == 10) || (resto == 11)) resto = 0
  if (resto != parseInt(cpf.substring(10, 11))) return false
  return true
}
