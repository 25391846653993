import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOverlayService } from 'src/app/services/mat-overlay.service';
import { OverlayConfig } from '@angular/cdk/overlay';
import { TableFilterService } from './table-filter.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TableFilterDialogComponent } from './table-filter-dialog/table-filter-dialog.component';
import { HelperService } from 'src/app/utils/helper.service';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.css']
})
export class TableFilterComponent implements OnDestroy, OnChanges {

  @Input() filters: any[] = [];
  @Input() defaultFilterLoading: boolean = false;
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();
  defaultFilterForm: FormControl = new FormControl("");
  filterForm: FormGroup = new FormGroup({});
  chipsSubscriber: Subscription;
  chips: any[] = [];

  constructor(
    private helper: HelperService,
    private dialog: MatDialog,
    private tableFilterService: TableFilterService,
    private fb: FormBuilder
  ) {
    this.defaultFilterForm.valueChanges.subscribe(value => {
      this.filterChange.emit(this.filtersOutput());
    });
    this.tableFilterService.getFilter().subscribe(filter => {
      this.ObjectKeys(filter).forEach(key => {
        this.filterForm.get(key)?.patchValue(filter[key]);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters && changes.filters.currentValue) {
      const filters = changes.filters.currentValue;
      const formGroup = filters.map(filter => {
        switch (filter.type) {
          case 'select-multiple':
            return {
              [filter.name]: new FormControl(filter.defaultFilter)
            }
          case 'date-range':
            return {
              [filter.name]: new FormGroup({
                inicio: new FormControl(filter.defaultFilter.inicio),
                fim: new FormControl(filter.defaultFilter.fim)
              })
            }
          case 'date':
            return {
              [filter.name]: new FormControl(filter.defaultFilter)
            }
          default:
            return {
              [filter.name]: new FormControl(filter.defaultFilter)
            }
        }
      }).reduce((acc, cur) => {
        return { ...acc, ...cur }
      }, {});

      this.filterForm = this.fb.group(
        formGroup
      );
      this.filterForm.valueChanges.subscribe(() => {
        this.filterChange.emit(this.filtersOutput());
      });
    }
  }

  ngOnDestroy() {
  }

  openDialog(event: MouseEvent): void {
    const origin = event.target as HTMLElement;
    const dialogRef = this.dialog.open(TableFilterDialogComponent, {
      position: { left: `${origin.getBoundingClientRect().x - 450}px`, top: `${origin.getBoundingClientRect().y + 52}px` },
      width: '500px',
      maxHeight: '90vh',
      maxWidth: '100%',
      disableClose: false,
      backdropClass: 'table-filter-backdrop',
      data: { filters: this.filters, filterForm: this.filterForm }
    });
    const updatePosition = () => {
      dialogRef.updatePosition({ left: `${origin.getBoundingClientRect().x - 450}px`, top: `${origin.getBoundingClientRect().y + 52}px` })
    }
    window.addEventListener('resize', updatePosition);

    dialogRef.afterClosed().subscribe(result => {
      window.removeEventListener('resize', updatePosition);
    });
  }

  filtersOutput(): any {
    const defaultFilter = { 'filter[paginate]': this.defaultFilterForm.value };
    const filter = this.filterForm.value;

    const filterOut = this.ObjectKeys(filter).map(key => {
      switch (this.filters.find(filter => filter.name === key).type) {
        case 'select-multiple':
          if (!this.compareArrays(filter[key], this.filters.find(filter => filter.name === key).defaultFilter)) {
            const keyString = `filter[${key}]`;
            return { [keyString]: filter[key].toString() };
          }
          break;
        case 'date-range':
          if (filter[key].inicio && filter[key].fim) {
            const keyStringInicio = `filter[${key}Desde]`;
            const keyStringFim = `filter[${key}Ate]`;
            return { [keyStringInicio]: this.formatFilterData(filter[key].inicio), [keyStringFim]: this.formatFilterData(filter[key].fim) };
          }
          if (filter[key].inicio) {
            const keyStringInicio = `filter[${key}Desde]`;
            return { [keyStringInicio]: this.formatFilterData(filter[key].inicio) };
          }
          if (filter[key].fim) {
            const keyStringFim = `filter[${key}Ate]`;
            return { [keyStringFim]: this.formatFilterData(filter[key].fim) };
          }
          break;
        case 'date':
          if (filter[key]) {
            const keyString = `filter[${key}]`;
            return { [keyString]: this.formatFilterData(filter[key]) };
          }
          break;
        default:
          const keyString = `filter[${key}]`;
          return { [keyString]: filter[key] };
      }
    }).filter(filter => filter !== undefined).reduce((acc, cur) => {
      return { ...acc, ...cur }
    }, {});
    return  this.defaultFilterForm.value ? { ...defaultFilter, ...filterOut } : filterOut;
  }


  updateChips() {
    const filter = this.filterForm.value;

  }

  clearFilters(){
    this.filters.forEach(filter => {
      this.filterForm.get(filter.name)?.setValue(filter.defaultFilter);
    });
  }

  removeChip(formControl, defaultFilter) {
    formControl.setValue(defaultFilter);
  }
  removeSelectMultipleChip(formControl, chip) {
    formControl.setValue(formControl.value.filter(c => c !== chip));
  }

  getChipColor(chip, chipOptions) {
    const chipColor = chipOptions.find(option => option.value === chip).color;
    return chipColor ? chipColor : '#e0e0e0';
  }
  getChipLabel(chip, chipOptions) {
    return chipOptions.find(option => option.value === chip).label;
  }
  formatChipData(data) {
    return new Date(data).toLocaleDateString()
  }
  formatFilterData(data): string {
    return this.helper.formatarData(data).split(' ')[0];
  }
  compareArrays(array1, array2): boolean {
    if (array1.length !== array2.length) {
      return false;
    }
    for (let i = 0; i < array2.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }
  ObjectKeys(obj): string[] {
    return Object.keys(obj);
  }
  ObjectValues(obj): any[] {
    return Object.values(obj);
  }
}
