import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { AnexoComponent } from "./anexo/anexo.component";
import { DialogConfirmComponent } from "./dialogs/dialog-confirm/dialog-confirm.component";
import { DialogFormComponent } from "./dialogs/dialog-form/dialog-form.component";
import { DialogInfoComponent } from "./dialogs/dialog-info/dialog-info.component";
import { DialogsComponent } from "./dialogs/dialogs.component";
import { DynamicListComponent, DynamicListItemComponent } from './dynamic-list/dynamic-list.component';
import { EmitirDeclaracaoComponent } from "./emitir-declaracao/emitir-declaracao.component";
import { EnquadramentoSelectDialogComponent } from "./enquadramento-select/enquadramento-select-dialog/enquadramento-select-dialog.component";
import { EnquadramentoSelectComponent } from "./enquadramento-select/enquadramento-select.component";
import { ExportHTMLComponent } from './export-html/export-html.component';
import { FooterComponent } from "./footer/footer.component";
import { FormFrameComponent } from './form-frame/form-frame.component';
import { HeaderViewComponent } from "./header-view/header-view.component";
import { HeaderComponent } from "./header/header.component";
import { NavItemComponent } from "./header/nav-item/nav-item.component";
import { ProfileComponent } from './header/profile/profile.component';
import { InputLoadingComponent } from './input-loading/input-loading.component';
import { ModuleSidenavComponent } from './module-sidenav/module-sidenav.component';
import { ResizableContainerComponent } from './resizable-container/resizable-container.component';
import { AddServidorComponent } from './servidores/add-servidor/add-servidor.component';
import { BuscaServidorComponent, selectVinculoComponent } from './servidores/busca-servidor/busca-servidor.component';
import { EditServidorComponent } from './servidores/edit-servidor/edit-servidor.component';
import { TableFilterDialogComponent } from './table-filter/table-filter-dialog/table-filter-dialog.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { TableComponent } from "./table/table.component";
import { ValidateDialogComponent } from "./validate-dialog/validate-dialog.component";
import { NovoAgenteDialogComponent } from './novo-agente-dialog/novo-agente-dialog.component';
import { BuscaCpfFormComponent } from './servidores/busca-cpf-form/busca-cpf-form.component';
import { QuillDeltaEditorComponent } from './quill-delta-editor/quill-delta-editor.component';
import { QuillModule } from "ngx-quill";

@NgModule({
  declarations: [
    HeaderViewComponent,
    DialogConfirmComponent,
    DialogFormComponent,
    DialogInfoComponent,
    DialogsComponent,
    AnexoComponent,
    TableComponent,
    BuscaServidorComponent,
    AddServidorComponent,
    InputLoadingComponent,
    EditServidorComponent,
    DynamicListComponent,
    DynamicListItemComponent,
    ModuleSidenavComponent,
    selectVinculoComponent,
    ResizableContainerComponent,
    ExportHTMLComponent,
    EmitirDeclaracaoComponent,
    FormFrameComponent,
    HeaderComponent,
    FooterComponent,
    EnquadramentoSelectComponent,
    EnquadramentoSelectDialogComponent,
    ValidateDialogComponent,
    NavItemComponent,
    ProfileComponent,
    TableFilterComponent,
    TableFilterDialogComponent,
    NovoAgenteDialogComponent,
    BuscaCpfFormComponent,
    QuillDeltaEditorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCheckboxModule,
    OverlayModule,
    MatCardModule,
    MatSortModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatChipsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    QuillModule.forRoot()
  ],
  exports: [
    MatAutocompleteModule,
    TableComponent,
    TableFilterComponent,
    HeaderViewComponent,
    DialogConfirmComponent,
    DialogFormComponent,
    DialogInfoComponent,
    DialogsComponent,
    AnexoComponent,
    BuscaServidorComponent,
    BuscaCpfFormComponent,
    InputLoadingComponent,
    DynamicListComponent,
    DynamicListItemComponent,
    ModuleSidenavComponent,
    ResizableContainerComponent,
    ExportHTMLComponent,
    EmitirDeclaracaoComponent,
    FormFrameComponent,
    HeaderComponent,
    FooterComponent,
    EnquadramentoSelectComponent,
    ValidateDialogComponent,
    QuillDeltaEditorComponent
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'pt-BR',
    },
    provideNgxMask()
  ],
})
export class ComponentsModule { }
