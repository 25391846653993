import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableFilterService {

  private filter = new BehaviorSubject<{}>('');

  constructor() { }

  setFilter(filter: {}) {
    this.filter.next(filter);
  }
  getFilter(): Observable<{}> {
    return this.filter.asObservable();
  }
}
