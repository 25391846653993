<div class="container-fluid main">
    <div class="text-end p-1 text-light">
        <a href="/" class="text-light">SAIR</a>
    </div>
    <h4 class="text-light text-center my-5">Olá {{nomeServidor}}</h4>
    <h3 class="text-light text-center my-5">Bem-vindo(a) ao Sistema de Controle de Procedimentos Administrativos Correcionais - SISPAC</h3>
    <h4 class="text-light text-center my-5">Você tem acesso aos seguintes módulos:</h4>
    <div class="modules d-flex align-items-center justify-content-center">
        <div class="module-icon" *ngFor="let m of modulos" (click)="getPerfilUsuarioModulo(m)">
            <img class="icon" src="../../../assets/img/{{m.sigla.toLowerCase()}}.svg" alt="{{m.sigla}}">
            <p class="label">{{getModuloLabel(m.sigla)}}</p>
        </div>
    </div>
    <div class="modules d-flex align-items-center justify-content-center">
      <a class="module-icon" routerLink="/chatbot">
          <img class="icon" src="../../../assets/img/chatbot_icon.png" alt="ícone chatbot">
          <p class="label">Norma Chatbot</p>
      </a>
  </div>
</div>
