<mat-form-field [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="text"
    mask="000.000.000-00"
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="requiredAttr"
    [disabled]="disabled"
    (keyup)="keyPressSubmit($event)"
  />
  <mat-error *ngIf="requiredAttr">Campo Obrigatório</mat-error>
  <button
    matIconSuffix
    mat-icon-button
    class="bg-light mr-1"
    (click)="getServidor()"
    [disabled]="disabled"
    matTooltip="{{ btnTooltip ? btnTooltip : 'Incluir servidor' }}"
    matTooltipClass="example-tooltip-conf"
    matTooltipPosition="above"
    required
  >
    <mat-icon>{{ btnIcon }}</mat-icon>
  </button>
  <app-input-loading [isLoading]="servidorLoading"></app-input-loading>
</mat-form-field>
