<div class="container-login">
    <div class="d-flex justify-content-between w-100">
        <span class="page-title m-4">Subcontroladoria do Sistema de Correição e Contas</span>
        <img id="logo" class="m-4" src="../../../assets/img/logo-estado.png" alt="imagem">
    </div>
    <br>
    <div class="d-flex justifiy-center">
        <h3 class="text-center text-light font-weight-light">Sistema de Controle de Procedimentos Administrativos Correcionais - SISPAC</h3>
    </div>
    <br>

    <mat-card appearance="outlined" class="card-signin">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="form-signin">
            <mat-card-title class="text-center p-2">
                <p class="card-title text-light rounded">Autenticação</p>
            </mat-card-title>
            <mat-card-content class="d-flex flex-column justify-content-center align-items-center">
                <mat-form-field appearance="outline">
                    <mat-label>Usuário(CPF)</mat-label>
                    <input matInput placeholder="000.000.000-00" mask="000.000.000-00" formControlName="login">
                    <mat-error *ngIf='userForm.controls.login.hasError("minlength")'>CPF inválido</mat-error>
                    <mat-error *ngIf='userForm.controls.login.hasError("required")'>*campo obrigatório</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput placeholder="*********" formControlName="password" type="password">
                    <mat-error *ngIf='userForm.controls.password.hasError("required")'>*campo obrigatório</mat-error>
                </mat-form-field>
            </mat-card-content>
            <div *ngIf='isProgress' class="text-center">
                <i>Aguarde...</i>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <mat-card-actions class="d-flex flex-column align-items-center">
                <button mat-raised-button color="primary" *ngIf='!isProgress' class="mb-3">Entrar</button>
                <button mat-button (click)='reenviarSenha()' [disabled]='userForm.controls.login.invalid || isProgress'>Reenviar senha</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
