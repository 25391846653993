import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ServidorService } from '../servidor.service';
import { take } from 'rxjs';
import { RhNetService } from 'src/app/services/rhNet.service';
import Servidor from 'src/app/modules/admin/models/Servidor';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'busca-cpf-form',
  templateUrl: './busca-cpf-form.component.html',
  styleUrls: ['./busca-cpf-form.component.css'],
})
export class BuscaCpfFormComponent {
  servidorLoading: boolean = false;

  constructor(
    private rhNet: RhNetService,
    private snackBar: SnackBarService
  ) {

  }

  @Input() control = new FormControl;
  @Input() requiredAttr = false;
  @Input() disabled = false;
  @Input() btnTooltip = 'Buscar dados por CPF';
  @Input() label = 'CPF';
  @Input() placeholder = 'CPF';
  @Input() appearance = 'outline';
  @Input() btnIcon = 'search';
  @Output()  servidor = new EventEmitter<Servidor>();

  getServidor() {
    this.control.disable();
    this.servidorLoading = true;
    this.rhNet.getServidorByCPF({ cpf: this.control.value })
      .pipe(take(1))
      .subscribe({
        next: (servidor: Servidor) => {
          this.control.enable();
          this.servidorLoading = false;
          this.servidor.emit(servidor);
          this.snackBar.showSnackBarSuccess("CPF encontrado!");
        },
        error: error => {
          switch (error.error.code) {
            case 45:
              console.error(error);
              this.servidorLoading = false;
              this.control.enable();
              this.snackBar.showSnackBarError('Servidor não encontrado');
              break;
            default:
              console.error(error);
              this.servidorLoading = false;
              this.control.enable();
              this.snackBar.showSnackBarError('Erro ao conectar ao RHNet');
              break;
          }
        }
      })
  }

  keyPressSubmit(event) {
    event.key === "Enter" ? this.getServidor() : null;
  }
}
