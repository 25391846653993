import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from '../authentication/login/login.service';


@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  perfilUsuario: any;
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getPerfilUsuarioPorModulo(idModulo) {
    return this.http.get(this.baseUrl + 'loginModulo', {
      params: new HttpParams().set('idModulo', idModulo),
      headers: new HttpHeaders(this.loginService.getHeaderComTokenUsuario())
    }).toPromise()
  }

  initSistema(data) {
    this.perfilUsuario = {
      "id_perfil": data.perfilModulo[0].id_perfil,
      "desc_perfil": data.perfilModulo[0].desc_perfil,
    };
  }
}
