import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EmitirDeclaracoesService } from './emitir-declaracao.service';

@Component({
  selector: 'app-emitir-declaracao',
  templateUrl: './emitir-declaracao.component.html',
  styleUrls: ['./emitir-declaracao.component.css']
})
export class EmitirDeclaracaoComponent implements OnInit {
  servidorForm: UntypedFormGroup;
  declaracaoPreview: string = "";

  emitirDisabled = true;

  emitirLoading = false;
  downloadLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private emitirDeclaracoesService: EmitirDeclaracoesService,
    private snackBar: SnackBarService
  ) {
    this.servidorForm = this.fb.group({
      id: [],
      nome: [{ value: null, disabled: true }, Validators.required],
    });
  }

  ngOnInit(): void {
  }

  buscarServidor(servidor) {
    this.servidorForm.patchValue(servidor);
    if (this.servidorForm.getRawValue().id) {
      this.emitirDisabled = false;
    } else {
      this.emitirDisabled = true;
    }
  }

  downloadDeclaracao() {
    this.downloadLoading = true;
    this.emitirDeclaracoesService.downloadDeclaracao({ servidorId: this.servidorForm.getRawValue().id })
      .subscribe(
        (declaracao: any) => {
          this.downloadLoading = false;
          const binaryData = atob(declaracao.pdf);

          const len = binaryData.length;
          const buffer = new ArrayBuffer(len);
          const view = new Uint8Array(buffer);
          for (let i = 0; i < len; i++) {
            view[i] = binaryData.charCodeAt(i);
          }

          const pdfBlob = new Blob([view], { type: 'application/pdf' });

          const url = URL.createObjectURL(pdfBlob);
          const link = document.createElement('a');
          link.href = url;
          link.download = declaracao.fileName;
          link.click();

          // Remove the link element after it has been used
          setTimeout(function () {
            URL.revokeObjectURL(url);
            link.remove();
          }, 100);
        },
        (error) => {
          console.error(error);
          this.downloadLoading = false;
          this.servidorForm.reset();
          this.emitirDisabled = true;
          this.emitirLoading = false;
          this.snackBar.showSnackBarError(error.error.message);
        })
  }
}
