<h1 matDialogTitle>Filtros</h1>
<div matDialogContent [formGroup]="data.filterForm">
  <div class="row">
    <div class="col-12">
      <ng-container *ngFor="let form of data.filters">
        <mat-form-field *ngIf="form.type === 'select-multiple'">
          <mat-label>{{ form.label }}</mat-label>
          <mat-select
            multiple
            [formControlName]="form.name"
            (click)="$event.stopPropagation()"
          >
            <mat-option
              *ngFor="let option of form.options"
              [value]="option.value"
              >{{ option.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="form.type === 'date-range'">
          <mat-label>{{ form.label }}</mat-label>
          <mat-date-range-input
            [formGroupName]="form.name"
            [rangePicker]="picker"
          >
            <input
              matStartDate
              placeholder="Data inicial"
              formControlName="inicio"
            />
            <input matEndDate placeholder="Data final" formControlName="fim" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field *ngIf="form.type === 'date'">
          <mat-label>{{ form.label }}</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [formControlName]="form.name"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>

<div matDialogActions class="pl-3 pb-3 pr-3">
  <button mat-button (click)="close()">Fechar</button>
  <button mat-button (click)="clearFilters()">Limpar Filtros</button>
</div>
