import {Component, OnInit} from '@angular/core';

@Component({
    selector:'master-root',
    templateUrl:'./master.component.html',
    styleUrls:['./master.component.css']

})

export class MasterComponent{
    
    title="Correição"
}