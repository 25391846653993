import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatOverlayService } from "src/app/services/mat-overlay.service";
import { ProfileComponent } from "./profile/profile.component";
import { Perfil } from "src/app/modules/admin/types/Perfil";
import { Button } from "protractor";
import { link } from "fs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: MatOverlayService
  ) {}
  nomeUsuario: string;
  lotacaoUsuario: string;
  permissaoUsuario: string;
  nomeModulo: string;
  siglaModulo: string;

  user: any;
  perfil: any;
  isOpen = false;

  navItems = [];

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.perfil = JSON.parse(localStorage.getItem("perfil"));
    this.nomeModulo = JSON.parse(localStorage.getItem("perfil")).desc_modulo;
    this.siglaModulo = JSON.parse(localStorage.getItem("perfil")).sigla;

    if (this.user) {
      this.nomeUsuario = this.user.nome;
      this.lotacaoUsuario = this.user.lotacao;
      this.permissaoUsuario = JSON.parse(
        localStorage.getItem("perfil")
      ).desc_perfil;
    } else {
      alert("Usuário não encontrado!");
      this.router.navigate(["/"]);
    }
    switch (this.siglaModulo) {
      case "TAC":
        this.montarPerfilTAC();
        break;
      case "PAD":
        this.montarPerfilPAD();
        break;
      case "SIND":
        this.montarPerfilSIND();
        break;
      case "TCA":
        this.montarPerfilTCA();
        break;
      case "API":
        this.montarPerfilAPI();
        break;
      case "MED":
        this.montarPerfilMED();
        break;
      case "TAC2":
        this.montarPerfilTAC2();
        break;
      default:
        break;
    }
  }
  montarPerfilTAC() {
    this.navItems.push({
      type: "menu",
      nome: "TAC",
      menuItems: [
        {
          nome: "Cadastrar",
          link: "tac/createtac",
        },
        {
          nome: "Consultar/Alterar",
          link: "tac/followtac",
        },
        {
          nome: "Incluir Documentos",
          link: "tac/listprocessosinclusaodocumentos",
        },
        {
          nome: "Incluir Declarações",
          link: "tac/listprocessosinclusaodeclaracoes",
        },
      ],
    });
    this.navItems.push({
      type: "button",
      nome: "Painel",
      link: "tac/dash",
    });
    this.navItems.push({
      type: "button",
      nome: "Comunicados",
      link: "tac/comunicados",
    });
    this.navItems.push({
      type: "link",
      target: "_blank",
      nome: "Manual",
      link: "../../../assets/files/manual-tac.pdf",
    });
    this.navItems.push({
      type: "button",
      nome: "Emitir Declaração",
      link: "tac/emitir-declaracao",
    });
    this.navItems.push({
      type: "link",
      target: "_blank",
      nome: "Tacômetro",
      link: "https://app.powerbi.com/view?r=eyJrIjoiNTMwZWIwNmYtN2ZkZC00MWNhLWJmZGYtMTM4YmM0MzA5ZGIxIiwidCI6IjY3ZmQ0MzFjLWIyYWQtNDg2Ny04MWJjLWQ3NTYyMjBiNTZkNCJ9",
    });
    if (this.perfil.id_perfil == 1) {
      this.navItems.push({
        type: "button",
        nome: "Usuários",
        link: "user",
      });
    }
  }
  montarPerfilPAD() {
    this.navItems.push({
      type: "button",
      nome: "PADs",
      link: "pad",
    });
    if (this.perfil.id_perfil == 1) {
      this.navItems.push(
        {
          type: "menu",
          nome: "Cadastros",
          menuItems: [
            {
              nome: "Comissão",
              link: "admin/comissao",
            },
            {
              nome: "Autoridade",
              link: "admin/autoridade",
            },
          ],
        },
        {
          type: "button",
          nome: "Usuários",
          link: "user",
        }
      );
    }
  }
  montarPerfilSIND() {
    if (this.perfil.codg_perfil == Perfil.ADMINISTRADOR) {
      this.navItems.push(
        {
          type: "button",
          nome: "Sindicâncias",
          link: "sind/list",
        },
        {
          type: "menu",
          nome: "Painel",
          menuItems: [
            {
              nome: "Geral",
              link: "sind/dash",
            },
            {
              nome: "Administrativo",
              link: "sind/dashAdmin",
            },
          ],
        },
        {
          type: "menu",
          nome: "Sindicômetro",
          menuItems: [
            {
              type: "link",
              target: "_blank",
              nome: "Geral",
              link: "https://app.powerbi.com/view?r=eyJrIjoiNTc1OGNkZmUtZmFjOS00MWYzLWIwNjgtNzVlMGNhYTQ1ZjBmIiwidCI6IjY3ZmQ0MzFjLWIyYWQtNDg2Ny04MWJjLWQ3NTYyMjBiNTZkNCJ9",
            },
            {
              type: "link",
              target: "_blank",
              nome: "Administrativo",
              link: "https://app.powerbi.com/view?r=eyJrIjoiZTRmZWRhMjItMjU2YS00OTYyLTk4YTMtZjYzNGFlZWVkNWY2IiwidCI6IjY3ZmQ0MzFjLWIyYWQtNDg2Ny04MWJjLWQ3NTYyMjBiNTZkNCJ9",
            },
          ],
        },
        {
          type: "menu",
          nome: "Cadastros",
          menuItems: [
            {
              nome: "Comissão",
              link: "admin/comissao",
            },
            {
              nome: "Autoridade",
              link: "admin/autoridade",
            },
          ],
        },
        {
          type: "button",
          nome: "Usuários",
          link: "user",
        }
      );
    } else {
      this.navItems.push(
        {
          type: "button",
          nome: "Sindicâncias",
          link: "sind/list",
        },
        {
          type: "button",
          nome: "Painel",
          link: "sind/dash",
        },
        {
          type: "link",
          target: "_blank",
          nome: "Sindicômetro",
          link: "https://app.powerbi.com/view?r=eyJrIjoiNTc1OGNkZmUtZmFjOS00MWYzLWIwNjgtNzVlMGNhYTQ1ZjBmIiwidCI6IjY3ZmQ0MzFjLWIyYWQtNDg2Ny04MWJjLWQ3NTYyMjBiNTZkNCJ9",
        }
      );
    }
  }
  montarPerfilTCA() {
    this.navItems.push({
      type: "button",
      nome: "Painel",
      link: "tca",
    });
    this.navItems.push({
      type: "button",
      nome: "TCA",
      link: "tca/list",
    });
    if (this.perfil.id_perfil == 1) {
      this.navItems.push({
        type: "button",
        nome: "Usuários",
        link: "user",
      });
    }
  }

  montarPerfilAPI() {
    this.navItems.push(
      {
        type: "button",
        nome: "Painel",
        link: "api/dash",
      },
      {
        type: "button",
        nome: "APIs",
        link: "api/list",
      }
    );
    if (this.perfil.id_perfil == 1) {
      this.navItems.push({
        type: "button",
        nome: "Usuários",
        link: "user",
      });
    }
  }

  montarPerfilMED() {
    this.navItems.push({
      type: "button",
      nome: "Mediação",
      link: "med/list",
    });
    if (this.perfil.id_perfil == 1) {
      this.navItems.push(
        {
          type: "button",
          nome: "Painel",
          link: "med/dash",
        },
        {
          type: "button",
          nome: "Usuários",
          link: "user",
        }
      );
    }
  }
  montarPerfilTAC2() {
    this.navItems.push({
      type: "button",
      nome: "TAC",
      link: "tac2/list",
    });

    this.navItems.push({
      type: "button",
      nome: "Painel",
      link: "tac2/dash",
    });
  }

  toggleProfileOverlay(event) {
    if (this.overlayService.isOpen()) {
      this.closeProfileOverlay();
    } else {
      this.openProfileOverlay(event);
    }
  }

  openProfileOverlay(event: MouseEvent) {
    this.overlayService.open(ProfileComponent, event.target);
  }

  closeProfileOverlay() {
    this.overlayService.close();
  }
}
